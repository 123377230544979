import React from "react"
import Layout from "../components/layout"
export default () => (
  <Layout>
    <h2>O mnie</h2>
    <p>Z wykształcenia jestem psychologiem, psychoterapeutką w trakcie procesu certyfikacji. Prowadzę psychoterapię indywidualną młodzieży, osób dorosłych oraz rodzin głównie w podejściu systemowym. Jestem członkiem Wielkopolskiego Towarzystwa Terapii Systemowej, a swoją pracę poddaję regularnej superwizji. 
    Doświadczenie zawodowe zdobywałam pracując w Poradniach Zdrowia Psychicznego, Środowiskowym Centrum Zdrowia Psychicznego oraz w Ośrodku Rehabilitacyjnym.  </p>

    <p>W swojej praktyce psychoterapeutycznej opieram się głównie na myśleniu systemowym (grunt teoretyczny: teorie systemów, informacji, cybernetyki I i II rzędu, teorie komunikacji, konstruktywizm radykalny, autopoieza, konstrukcjonizm społeczny). Korzystam też z założeń Terapii Stanów Ego, Systemu Wewnętrznej Rodziny (IFS), Terapii Narracyjnej. Ze względu na zainteresowania neuropsychologią, neurobiologią interpersonalną staram się integrować te dziedziny wiedzy z psychoterapią.</p>

    <p>Psychoterapia systemowa ma zastosowanie w terapii rodzinnej, w psychoterapii indywidualnej, par i grupowej. W podejściu tym człowiek definiowany jest jako jednostka komunikująca się z innymi, pozostająca z nimi w relacji i w sieci wzajemnych oddziaływań. Również psychopatologia w terapii systemowej postrzegana jest jako zjawisko z gruntu relacyjne. Ludzie bowiem nie żyją samotnie, w oderwaniu od wielowymiarowych kontekstów, lecz zawsze w pewnych powiązaniach społecznych.
    W pracy tej zarzucony zostaje model myślenia przyczynowo - skutkowego, linearnego na rzecz modelu przyczynowości cyrkularnej.
    </p>

    <p>Zapraszam wszystkich, którzy chcieliby przyjrzeć się sobie, swoim relacjom z innymi lub potrzebują wsparcia dla siebie, swoich bliskich w trudnych sytuacjach życiowych, w poradzeniu sobie z problemem. Podczas wspólnych spotkań ważne jest dla mnie stworzenie dla drugiej osoby bezpiecznej, pełnej szacunku przestrzeni uwzględniającej jej autonomię, zasoby oraz indywidualne potrzeby.</p>
    
    <h2>Wykształcenie</h2>
    <ul>
      <li>
        Uniwersytet im. Adama Mickiewicza w Poznaniu- 2011r. ukończone studia
        magisterskie na kierunku psychologia, specjalność psychoterapia zaburzeń psychicznych 
      </li>
      <li>
      Uniwersytet im. Adama Mickiewicza w Poznaniu - ukończone 4-letnie kształcenie podyplomowe specjalizacyjne z psychologii klinicznej
      </li>
      <li>Szkolenie psychoterapeutyczne w podejściu systemowym - obecnie w procesie certyfikacji zgodnej z wymogami Ministra Zdrowia, Wielkopolskie Towarzystwo Terapii Systemowej</li>
    </ul>
    <h2>Ukończone kursy, szkolenia:</h2>
    
    <ul>
      <li>Szkolenie "Systemowo-integracyjna pedagogika traumy i doradztwo specjalistyczne ukierunkowane na traumę" realizowane w oparciu o wytyczne Niemieckiego Towarzystwa Psychotraumatologii / Stowarzyszenia Specjalistycznego Pedagogiki Traumy Deutschsprachige Gesellschaft für Psychotraumatologie (DeGPT) / Fachverband Traumapädagogik (dawniej BAG e.V) - w trakcie.Wielkopolskie Towarzystwo Terapii Systemowej</li>

    <li>"Systemowa terapia dzieci i młodzieży", Wielkopolskie Towarzystwo Terapii Systemowej</li>

    <li>"Systemowy coaching dla rodziców", Wielkopolskie Towarzystwo Terapii Systemowej </li>

    <li>Szkolenie specjalistyczne "Brainspotting" faza 1. Brainspotting jest techniką leczenia szeroko pojętej traumy, opartą o neurobiologię i zdolności mózgu do samoregulacji. Został odkryty w 2003 roku przez Davida Granda, znanego między innymi jako innowatora metody EMDR.</li>

    <li>Stosowanie i interpretowanie skal inteligencji i rozwoju dla dzieci w wieku przedszkolnym IDS, Pracownia Testów Psychologicznych Polskiego Towarzystwa Psychologicznego</li>

    <li>Neuropsychologia kliniczna dziecka, Pracownia Pomocy Psychologicznej dla Dzieci Młodzieży i Rodzin w Sopocie, prowadząca - prof. Aneta Borkowska</li>
  
    <li>Terapia dziecka z mutyzmem selektywnym/wybiórczym - I, II etap, Centrum Terapii Mutyzmu, Warszawa</li>

    <li>Wczesna interwencja psychologiczna wobec małego dziecka i jego rodziny- problemy kliniczne w pierwszych trzech latach życia dziecka, Pracownia Pomocy Psychologicznej dla Dzieci i Młodzieży i Rodzin w Sopocie, prowadząca – dr hab. Grażyna Kmita</li>

    <li>Metoda ruchu rozwijającego W. Sherborne</li>

    <li>III stopniowy kurs doskonalący terapia behawioralna dzieci z autyzmem</li>

    <li>PECS - poziom I</li>

    <li>Makaton - poziom I</li>
    <li>Alternatywne i wspomagające sposoby porozumiewania się osób niemówiących – poziom podstawowy oraz zaawansowany, Stowarzyszenie Mówić Bez Słów</li>
    </ul>
  </Layout>
)
